<template>
  <div class="applications" :class="{skeleton: !state.loaded}">
    <div class="header">
      <div class="title">
        <span>나의 신청서</span>
      </div>
      <div class="desc">
        <span>상세 내용을 보시려면 해당 항목을 선택해주세요.</span>
      </div>
    </div>
    <div class="items">
      <div class="item" v-for="(a, idx) in state.applications" :key="idx" :class="{finished: a.finished && !a.canceled, canceled: a.canceled}">
        <Anchor :href="a.canceled ? '' : `/forms/${a.formName}/applications/${a.id}`" class="d-flex justify-content-between align-items-center">
          <div class="content">
            <div class="date color-secondary">
              <span>최근 입력: </span>
              <span>{{ a.saveDate }}</span>
            </div>
            <div class="title d-flex">
              <span class="badge">{{ a.canceled ? "취소됨" : a.finished ? "신청 완료" : "작성 중" }}</span>
              <span class="text">{{ a.formTitle }}</span>
            </div>
          </div>
          <button class="btn btn-light" @click.prevent="cancel(a.formId, a.id)" v-if="!a.canceled">취소</button>
        </Anchor>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "@/scripts/mixin";
import {defineComponent, reactive} from "@vue/composition-api";
import store from "@/scripts/store";
import http from "@/scripts/http";
import Anchor from "@/components/Anchor";

function Component(initialize) {
  this.name = "pageFormApplications";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Anchor},
  mixins: [mixin],
  setup() {
    const component = new Component(async () => {
      load();
    });

    const state = reactive({
      applications: [],
      loaded: false,
    });

    const load = () => {
      state.loaded = false;
      for (let i = 0; i < 3; i += 1) {
        state.applications.push({
          formTitle: "Please wait a moment",
          saveDate: "0000*00-00 00:00:00",
          finished: false
        });
      }
      http.get("/api/form/applications").then(({data}) => {
        state.applications = data.body;
        state.loaded = true;
      });
    };

    const cancel = (formId, applicationId) => {
      store.commit("confirm", {
        message: "해당 신청을 취소하시겠습니까?",
        allow() {
          http.put(`/api/forms/${formId}/applications/${applicationId}/cancel`).then(async () => {
            load();
            await store.commit("setSwingMessage", "신청을 취소하였습니다.");
          });
        }
      });
    };

    return {component, state, cancel,};
  }
});
</script>

<style lang="scss" scoped>
.applications {
  padding: $px50 $px15;

  .header {
    padding: 0 $px20;

    .title {
      font-size: $px19;
      font-weight: 900;
    }

    .desc {
      color: #666;
      font-size: $px14;
      padding-top: $px7;
    }
  }

  > .items {
    padding-top: $px50;

    > .item {
      border-radius: $px4;
      border: $px1 solid #f7f7f7;

      > a {
        display: block;
        font-size: $px14;
        text-decoration: none;
        padding: $px20;

        .content {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          .date {
            font-size: $px11;
          }

          .title {
            padding-top: $px5;
            align-items: center;

            .badge {
              background-color: #eee;
              display: inline-block;
              font-size: $px10;
              padding: $px4;
              margin-right: $px6;
            }

            .text {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .btn {
          flex-shrink: 0;
          font-size: $px11;
          margin-left: $px20;
        }
      }

      &.finished > a .content .title .badge {
        background-color: $colorPurple;
        color: #fff;
      }

      &.canceled > a {
        .content {
          opacity: 0.5;
        }
      }

      &:not(.canceled) > a:hover {
        background: #f7f7f7;
      }

      &:not(:last-child) {
        margin-bottom: $px12;
      }
    }
  }

  &.skeleton {
    > .header span {
      @include skeleton;
    }

    .items .item {
      a {
        cursor: default;

        .content span, .btn {
          @include skeleton;
        }
      }

      &:hover {
        background: initial;
      }
    }
  }
}
</style>